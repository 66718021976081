.About-title {
  color:#612325;
}

.About-info-container {
  margin: 0 auto;
  max-width: 800px;
  margin-bottom: 10px;
  border-radius: 15px;;
  background-color: #eee4dc;
  padding: 40px;
}

.About-info-container > b > a {
  color: rgb(138, 123, 93);
}

.About-info-container > b > a:hover {
  opacity: 0.5;
}

.About-SE {
  color: rgb(138, 123, 93);
}

.About-work {
  font-size: 20px;
}

.About-info {
  color: rgb(138, 123, 93);
  animation-name: moveLeftRight;
  animation-duration: 2s;
  animation-fill-mode: both;
  border-bottom: 5px solid #612325;
  border-top: 5px solid #612325;
  margin-right: 15rem;
  margin-left: 15rem;
  padding-bottom: 10px;
}

.About-info-pre {
  float: left;
  max-width: 400px;
}

.About-info-body {
  float:right;
  max-width: 550px;
  text-align: left;
  padding: 10px;
}


@media only screen and (max-width: 500px){
  .About-info {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .About-info-body {
    padding: 8px;
  }
  .About-info-pre {
    padding: 8px;
  }
}

@keyframes moveLeftRight {
  from {
    transform: translateX(-1000px);
  }

  to {
    transform: translate(0px)
  }
}