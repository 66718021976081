body {
  padding-bottom: 10vh;
}

.headshot {
  width: 300px;
  height: 300px;
  border-radius: 70px;
  position: absolute;
  left: 10rem;
  top: 6rem;
  animation-name: moveLeftRight;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  opacity: 0.85;
}

.container {
  text-align: center;
}

.Home-intro {
  color: rgb(138, 123, 93);
  font-size: 20px;
  margin-top: 100px;
}

.Home-name {
  color: #612325;
  font-size: 60px;
}

.Home-info {
  color:rgb(138, 123, 93);
  font-size: 20px;
  animation-name: moveRightLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.Home-btn-div {
  margin-top: 7rem;
  animation-name: moveRightLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
}

.Home-projects-btn {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: none;
  height: 50px;
  border-radius: 50px;
  background-color: #eee4dc;
  padding: 15px;
  font-size: 3.5vh;
  color: #612325;
  box-shadow: 5px 8px #d4cbc3;
  margin-right: 20px;
  cursor: pointer;
}

.Home-projects-btn:hover {
  opacity: 0.5;
}

@media only screen and (max-width: 1100px) {
  .Home-intro {
    margin-top: 60px;
  }

  .Home-projects-btn{
   font-size: 15px;
  }
  .Home-btn-div {
    margin-top: 4rem;
  }

  .Home-name {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .headshot {
    width: 250px;
    height: 250px;
    position: relative;
    left: 0.2rem;
    top: 2rem;
  }
}


@keyframes moveRightLeft {
  from {
    transform: translateX(900px);
  }

  to {
    transform: translate(0px)
  }
}

@keyframes moveLeftRight {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translate(-900px)
  }
}



