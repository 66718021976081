
.Contact-title {
  color: #612325;
  border-bottom: 5px solid #612325;
  margin-right: 10rem;
  margin-left: 10rem;
  padding-bottom: 20px;
}

.Contact-icon {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: 20px;
}

.Contact-icon-div {
  animation-name: moveDownUp;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.Contact-icon:hover {
  opacity: 0.5;
}

@keyframes moveDownUp{
  from {
    transform: translateY(500px);
  }

  to {
    transform: translateY(0px)
  }
}

@media only screen and (max-width: 500px){
  .Contact-title {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
