.NavBar-container {
  overflow: hidden;
  background-color: #eee4dc;
  text-align: left;
}

.NavBar-list {
  display: inline;
}

.NavBar-link {
  text-decoration: none;
  color: rgb(138, 123, 93);
  margin: 120px;
}

.NavBar-link.active {
  color: #612325;
}

.NavBar-link:hover {
  opacity: 0.5;
}

@media only screen and (max-width: 1400px) {
  .NavBar-link {
    margin: 7.5px;
  }
  .NavBar-list {
    font-size: 13px;
  }

}

