
.Project-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  margin-bottom: 10px;
  border-radius: 15px;
  background-color: #eee4dc;
}

.Project-container:after {
  content: "";
  display: block;
  clear: both;
}

.Projects-picture {
  border-radius: 15px;
  width: 500px;
  height: 375px;
  float: left;
}

.Project-context {
  float: right;
  max-width: 580px;
}

.Project-title {
  margin-top: 800px;
  color: #612325;
}

.Project-name {
  color: rgb(138, 123, 93);
  font-size: 30px;
}

.Project-link {
  text-decoration: none;
  color: rgb(138, 123, 93);
  font-size: 30px;
  padding: 10px;
}

.Project-btn {
  border-radius: 20px;
  border: none;
  background-color: #eee4dc;
}

.Project-btn:hover {
  transition: ease 0.4s;
}

.Project-link:hover {
  color: #612325;
}

.Project-info {
  color: rgb(100, 91, 70);
  font-size: 15px;
}

.Project-bottom {
  margin-bottom: 50px;
}


@media only screen and (max-width: 1120px){

  .Project-container {
    max-width: 300px;
  }

  .Project-title {
    font-size: 25px;
  }

  .Projects-picture {
    text-align: center;
    width: 300px;
    height: 200px;
    float: none;
  }

  .Project-context {
    width: 300px;
    float: none;
  }

}